export class EntityIcon {
  static readonly admin = 'admin_panel_settings'
  static readonly stats = 'leaderboard'
  static readonly report = 'assignment_late'
  static readonly company = 'store'
  static readonly website = 'language'
  static readonly phone = 'call'
  static readonly user = 'person'
  static readonly subscription = 'subscriptions'
  static readonly export = 'file_download'
  static readonly thumbUp = 'thumb_up'
  static readonly cancel = 'cancel'
  static readonly check_circle = 'check_circle'
  static readonly checklist = 'checklist'
}
